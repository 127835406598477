import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { anaSayfa, icerik } from '../api'
import headimg from '../image/head.png'
import geri from '../image/geri.png'
import { motion } from 'framer-motion'
import { icerikdetayVariant } from '../component/general'
import loading from '../image/loading.svg'
import { Modal } from 'reactstrap'

const Ilcemiz = () => {
    const [load, setLoad] = useState(false)
    const [log, setLog] = useState(false)
    const [imgLoad, setImgLoad] = useState(false)
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [datas, setDatas] = useState([])
    const [message, setMessage] = useState('')

    const getDatas = () => {
        axios.get(`${icerik}285`).then(res => {
            console.log(res)
            if (res.data.Status) {
                setDatas(res.data.Result)
                setLoad(true)
            }
        }).catch(e => {
            if (e.response.status === 500) {
                setModal(!modal)
                setLog(true)
            }
        })

        axios.get(anaSayfa).then(res => {
            if (res.data.Status) {
                setData(res.data.Result)
            }
        }).catch(e => {
            if (e.response.status === 500) {
                setModal(!modal)
                setLog(true)
                setMessage(e)
            }
        })
    }

    useEffect(() => {
        getDatas()
        setTimeout(() => {
            setImgLoad(true)
        }, 700)
    }, [])

    return (
        <motion.div {...icerikdetayVariant} className='ilcemiz'>
            <div className='head'>
                <div className='sol'>
                    <div className='head'><img src={headimg} alt='' /></div>
                    <div className='geri'><div onClick={() => window.history.back()}><img src={geri} alt='' /></div></div>
                </div>
                <div className='detay'><span>Detay</span></div>
                <div className='menuust'></div>
            </div>

            <div className='baslik'>
                <h6>{datas.Baslik}</h6>
            </div>

            <div className='kapakresim'>
                {imgLoad ? <img src={datas.KapakResmi} alt='' /> : <div className='image2load'><img src={loading} alt='' height={30} /></div>}
            </div>

            <div className='content'>
                <div dangerouslySetInnerHTML={{ __html: datas.Icerik }}></div>
            </div>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h4>Ereğli Belediyesi</h4></div>
                    <div>{message}</div>
                </div>
            </Modal>
        </motion.div>
    )
}

export default Ilcemiz