export const genelicerikVariant = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        opacity: 1,
        margin: -100,
        width: '50%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 120,
        paddingBottom: 100,
        transition: { duration: 0.5 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.5 }
    }
}

export const icerikVariant = {
    initial: {
        marginBottom: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        marginLeft: 20,
        marginRight: 20,
        padding: 20,
        marginTop: 1000,
        border: 0,
        zIndex: 0,
        overflowX: 'scroll'
    },
    animate: {
        marginBottom: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        marginLeft: 20,
        marginRight: 20,
        padding: 20,
        marginTop: 10,
        border: 0,
        zIndex: 0,
        overflowX: 'scroll'
    },
    exit: {

    }
}

export const icerikdetayVariant = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        opacity: 1,
        margin: -100,
        width: '0%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 100,
        transition: { duration: 0.2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 }
    }
}

export const homeVariant = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        opacity: 1,
        margin: -100,
        width: '0%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        transition: { duration: 0.2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 }
    }
}

export const mobilVariant = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        opacity: 1,
        margin: -100,
        width: '0%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 100,
        paddingBottom: 100,
        transition: { duration: 0.2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 }
    }
}

export const imageVariant = {
    initial: {
        paddingBottom: -200,
        width: '100%',
        height: 140,
        opacity: 0,
    },
    animate: {
        marginBottom: 30,
        width: '100%',
        height: 140,
        opacity: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 }
    }
}

export const newsVariant = {
    initial: {
        marginBottom: 100,
        width: '100%',
        height: 140,
        opacity: 0,
        paddingTop: 70
    },
    animate: {
        marginBottom: 0,
        width: '100%',
        height: 140,
        opacity: 1,
        paddingTop: 20,
        transition: { duration: 0.3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 }
    }
}

export const videoGalleryVariant = {
    initial: {
        paddingBottom: 20,
        width: '100%',
        height: 140,
        opacity: 0,
    },
    animate: {
        marginBottom: 80,
        width: '100%',
        height: 140,
        opacity: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 }
    }
}

export const contentImageVariant = {
    initial: {
        width: '100%',
        height: 200,
        opacity: 0,
    },
    animate: {
        width: '100%',
        height: 200,
        opacity: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 }
    }
}

export const fotoGaleriVariant = {
    initial: {
        display: 'inline-block',
        fontSize: 12,
        marginBottom: 20,
        opacity: 0,
    },
    animate: {
        display: 'inline-block',
        fontSize: 12,
        marginBottom: 20,
        opacity: 1,
        transition: { duration: 2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 2 }
    }
}

export const anaSlaytVariant = {
    initial: {
        marginTop: 70,
        width: '100%',
        zIndex: -1,
        position: 'relative',
        opacity: 0,
    },
    animate: {
        marginTop: 70,
        width: '100%',
        zIndex: -1,
        position: 'relative',
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const baskanImgVariant = {
    initial: {
        height: 130,
        borderRadius: 20,
        opacity: 0,
    },
    animate: {
        height: 130,
        borderRadius: 20,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const haberImgVariant = {
    initial: {
        width: 120,
        height: 120,
        borderRadius: '10px 10px 10px 10px',
        opacity: 0,
    },
    animate: {
        width: 120,
        height: 120,
        borderRadius: '10px 10px 10px 10px',
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const haberHomeVariant = {
    initial: {
        opacity: 0,
        marginLeft: -100,
    },
    animate: {
        marginLeft: 0,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const cenazeHomeVariant = {
    initial: {
        opacity: 0,
        marginTop: 500,
    },
    animate: {
        marginTop: 0,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}