import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import HomePage from '../../page/homepage'
import Belediyemiz from '../../page/belediyemiz'
import Ilcemiz from '../../page/ilcemiz'
import BizeYazin from '../../page/bizeYazin'
import Pages from '../../page/pages'
import IcerikDetay from '../../page/icerikdetay'
import GenelIcerik from '../../page/genelicerik'

const AnimatedRoutes = () => {
    const location = useLocation()

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact index path='/' element={<HomePage />} />
                <Route path='/belediyemiz' element={<Belediyemiz />} />
                <Route path='/ilcemiz' element={<Ilcemiz />} />
                <Route path='/bizeYazin/:id' element={<BizeYazin />} />
                <Route path='/icerik/:boolen/:id/:tip' element={<Pages />} />
                <Route path='/icerikdetay/:id' element={<IcerikDetay />} />
                <Route path='/genelicerik/:tip' element={<GenelIcerik />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes