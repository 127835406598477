import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { anaSayfa, icerikdetay } from '../api'
import headimg from '../image/head.png'
import geri from '../image/geri.png'
import dosya from '../image/dosya.png'
import { motion } from 'framer-motion'
import { Card } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { cenazeHomeVariant, contentImageVariant, icerikdetayVariant, videoGalleryVariant } from '../component/general'
import loading from '../image/loading.svg'

const IcerikDetay = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [load, setLoad] = useState(false)
    const [fotoGaleriLoad, setFotoGaleriLoad] = useState(false)
    const [videoGaleriLoad, setVideoGaleriLoad] = useState(false)
    const [datas, setDatas] = useState([])
    const counter = useRef(0);
    const imageLoaded = () => {
        if (localStorage.getItem('fotogalerisave') === id) {
            setTimeout(() => {
                setFotoGaleriLoad(true)
                setVideoGaleriLoad(true)
            }, 700)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setFotoGaleriLoad(true);
                setVideoGaleriLoad(true)
            }
        }
    }

    const getData = () => {
        axios.get(`${icerikdetay}${id}`).then(res => {
            if (res.data.Status) {
                localStorage.setItem('fotogalerisave', id)
                setData(res.data.Result)
                setLoad(true)
                setTimeout(() => {
                    setFotoGaleriLoad(true)
                    setVideoGaleriLoad(true)
                }, 700)
            }
        })

        axios.get(anaSayfa).then(res => {
            if (res.data.Status) {
                setDatas(res.data.Result)
            }
        })
    }


    useEffect(() => {
        getData()
    }, [])

    return (
        <motion.div {...icerikdetayVariant} className='icerikdetay'>
            <div className='head'>
                <div className='sol'>
                    <div className='head'><img src={headimg} alt='' /></div>
                    <div className='geri'><div onClick={() => window.history.back()}><img src={geri} alt='' /></div></div>
                </div>
                <div className='detay'><span>Detay</span></div>
                <div className='menuust'></div>
            </div>

            <div className='baslik'>
                <h6>{data.Baslik}</h6>
            </div>

            {data.KapakResmi !== null && data.KapakResmi !== undefined ? <div className='kapakresim'>
                <img src={datas.KapakResmi} alt='' />
            </div> : null}

            {data.Icerik !== null && data.Icerik ? <div className='content'>
                <div dangerouslySetInnerHTML={{ __html: data.Icerik }}></div>
            </div> : null}


            {data.FotoGaleri !== undefined && data.FotoGaleri !== null ? <motion.div {...cenazeHomeVariant} className='fotogaleri'>
                {
                    data.FotoGaleri !== undefined && data.FotoGaleri !== null ? data.FotoGaleri.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item.Resim} onLoad={imageLoaded} className='d-none' alt='' />
                                {fotoGaleriLoad ? <div key={index} className='fotogaleriitem'><motion.div {...contentImageVariant}>
                                    <img src={item.Resim} onLoad={imageLoaded} onClick={() => {
                                        setModalOpen(!modalOpen)
                                        setSelectedImage(item.Foto)
                                    }} alt='' />
                                </motion.div>
                                </div> : <div className='fotogaleri-load' key={index}>
                                    <div><img src={loading} alt='' /></div>
                                </div>}
                            </div>
                        )
                    }) : null
                }
            </motion.div> : null}


            {
                data.VideoGaleri !== undefined && data.VideoGaleri !== null ? data.VideoGaleri.map((item, index) => {
                    console.log(item)
                    return (
                        <div>
                            <img className='d-none' src={item.Resim} onLoad={imageLoaded} alt='' />
                            {
                                videoGaleriLoad ? <motion.div key={index} {...videoGalleryVariant}><div className='item-video' onClick={() => window.location.href = `${item.Video}`}>
                                    <img src={item.Resim} alt='' />
                                    <div className='text-center'><span>{item.Baslik}</span></div>
                                </div></motion.div> : <div className='fotogaleri-load' key={index}>
                                    <div><img src={loading} alt='' /></div>
                                </div>
                            }
                        </div>
                    )
                }) : null
            }

            {
                data.DosyaGaleri !== null && data.DosyaGaleri !== undefined ? data.DosyaGaleri.map((item, index) => {
                    return (
                        <Card className='border-0 m-2 p-3' key={index}>
                            <div className='d-flex justify-content-start'>
                                <div className='m-3'><img src={dosya} height={50} alt='' /></div>
                                <div>
                                    <div><h6>{item.Baslik}</h6></div>
                                    <div className='mt-2'><a href={item.Dosya} style={{ color: '#dcdc0f' }}>İndir</a></div>
                                </div>
                            </div>
                        </Card>
                    )
                }) : null
            }

            <div className="modal" style={modalOpen ? { display: 'flex' } : { display: 'none' }}>
                <span className="close" onClick={() => { setModalOpen(!modalOpen) }}>&times;</span>
                <img className="modal-content" src={selectedImage} alt='' />
            </div>
        </motion.div>
    )
}

export default IcerikDetay