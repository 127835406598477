import { Input, Card, Button, CardBody, Form } from 'reactstrap'
import message from '../image/mesaj.png'
import headimg from '../image/head.png'
import geri from '../image/geri.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { bizeUlasin } from '../api'
import axios from 'axios'
import LazyLoad from 'react-lazyload'
import { icerikdetayVariant } from '../component/general'

const BizeYazin = () => {
    const { handleSubmit } = useForm({})
    const { id } = useParams()

    const onSubmit = (values, e) => {
        axios.post(bizeUlasin, {
            'KonuSecimi': e.target[4].value,
            'AdSoyad': e.target[0].value,
            'Eposta': e.target[1].value,
            'Telefon': e.target[2].value,
            'Adres': e.target[3].value,
            'Konu': e.target[4].value,
            'Mesaj': e.target[5].value
        }).then(res => {
            console.log(res.data)
        })
    }

    return (
        <motion.div {...icerikdetayVariant} className='bize-yazin'>
            <div className='head'>
                <div className='sol'>
                    <div className='head'><img src={headimg} alt='' /></div>
                    <div className='geri'><div onClick={() => window.history.back()}><img src={geri} alt='' /></div></div>
                </div>
                <div className='detay'><span>{id === '0' ? 'Bize Yazın' : 'Başkana Yazın'}</span></div>
                <div className='menuust'></div>
            </div>

            <div className='baslik'>
                <div>
                    <div><span>Mesajım</span></div>
                    <div style={{ fontSize: 25, marginTop: -5 }}><span>Var!</span></div>
                </div>
                <div className='img'>
                    <LazyLoad><img src={message} height={30} alt='' /></LazyLoad>
                </div>
            </div>

            <div className='iletisim'>
                <Card className='border-0'>
                    <CardBody>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-group'>
                                <Input className='input' type='input' placeholder='Ad Soyad' />
                            </div>

                            <div className='form-group'>
                                <Input className='input' type='input' placeholder='E-Posta' />
                            </div>

                            <div className='form-group'>
                                <Input className='input' type='number' placeholder='Telefon' />
                            </div >

                            <div className='form-group'>
                                <Input className='input' type='input' placeholder='Adres' />
                            </div >

                            <div className='form-group'>
                                <Input className='input' type='input' placeholder='Konu' />
                            </div >

                            <div className='form-group'>
                                <Input className='input' type='textarea' placeholder='Mesajınız' />
                            </div >

                            <div className='butonbuton'><Button className='buton'>GÖNDER</Button></div>
                        </Form>
                    </CardBody >
                </Card >
            </div>
        </motion.div>
    )
}

export default BizeYazin