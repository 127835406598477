import React, { useState } from 'react'
import alo from '../image/alo.png'
import logo from '../image/logo.png'
import Drawer from 'react-motion-drawer'
import '../css/style.css'
import face from '../image/facebook.png'
import twit from '../image/twitter.png'
import youtube from '../image/youtube.png'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

const Header = ({ data }) => {
    const [canvasOpen, setCanvasOpen] = useState(false)
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <div className='header-menu'>
            {/* <div className='menus' onClick={() => setCanvasOpen(!canvasOpen)}>
                <img src={menu} alt=''/>
            </div> */}
            <div className='logo'>
                <a href='/'><img src={logo} alt='' /></a>
            </div>
            <div className='alo'>
                <a href='tel:+903327100707'><img src={alo} alt='' /></a>
            </div>

            <Drawer open={canvasOpen} toggle={() => { setCanvasOpen(!canvasOpen) }} className='drawermenu'>
                <div className='w-100 d-flex justify-content-end' onClick={() => setCanvasOpen(!canvasOpen)}><div className='close'>x</div></div>

                <div className='logo'><img src={logo} alt='' /></div>

                <div className='menuler'>
                    <Accordion flush open={open} toggle={toggle}>
                        {
                            data.SolMenu !== null && data.SolMenu !== undefined ? data.SolMenu.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            item.AltMenu !== undefined ? <AccordionItem>
                                                <AccordionHeader targetId={index.toString()}>{item.Baslik}</AccordionHeader>
                                                {
                                                    item.AltMenu !== undefined ? <AccordionBody accordionId={index.toString()}>
                                                        {
                                                            item.AltMenu.map((value, key) => {
                                                                return (
                                                                    <div key={key} className='altbaslik'>
                                                                        {value.Tip !== 7 && value.Tip !== 5 ? <a href={`/icerik/${`1`}/${value.Id}/0`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                            <div>{value.Baslik}</div>
                                                                        </a> : <a href={`/icerik/${`1`}/${value.Id}/${value.Tip}`} style={{ textDecoration: 'none', color: 'black' }}><div>{value.Baslik}</div></a>}
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </AccordionBody> : null
                                                }
                                            </AccordionItem> : <div className='altsiz'>
                                                <a href={item.Tip !== '2' || item.Tip !== '3' || item.Tip !== '4' || item.Tip !== '5' || item.Tip !== '6' || item.Tip !== '8' ? `/genelicerik/${item.Tip}` : `/icerik/${`2`}/${item.Tip}`} style={{ textDecoration: 'none', color: 'black' }}>{item.Baslik}</a>
                                            </div>
                                        }
                                    </div>
                                )
                            }) : null
                        }
                    </Accordion>
                </div>

                <hr style={{ color: 'grey', width: '260px', marginLeft: 20 }} />

                <div className='social'>
                    <div><img src={face} alt='' /></div>
                    <div><img src={twit} alt='' /></div>
                    <div><img src={youtube} alt='' /></div>
                </div>
            </Drawer>
        </div>
    )
}

export default Header