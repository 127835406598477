import React, { useEffect, useState } from 'react'
import '../css/style.css'
import whatsapp from '../image/whatsapp.png'
import kamera from '../image/kamera.png'
import Header from '../component/header'
import { motion } from 'framer-motion'
import { anaSlaytVariant, baskanImgVariant, cenazeHomeVariant, haberImgVariant, homeVariant } from '../component/general'
import loading from '../image/loading.svg'
import axios from 'axios'
import { anaSayfa } from '../api'

const HomePage = () => {
    const [tab, setTab] = useState(1)
    const [image1Load, setImage1Load] = useState(false)
    const [image2Load, setImage2Load] = useState(false)
    const [image3Load, setImage3Load] = useState(false)
    const [data, setData] = useState([])

    const onLoadImg = () => {
        localStorage.setItem('homesave', '1')
        setTimeout(() => {
            setImage1Load(true)
            setImage2Load(true)
            setImage3Load(true)
        }, 400)

        // if (localStorage.getItem('homesave') === '1') {
        //     setTimeout(() => {
        //         setImage1Load(true)
        //         setImage2Load(true)
        //         setImage3Load(true)
        //     }, 400)
        // } else {
        //     setTimeout(() => {
        //         setImage1Load(true)
        //         setImage2Load(true)
        //         setImage3Load(true)
        //     }, 1000)
        // }
    }

    const getData = () => {
        axios.get(anaSayfa).then(res => {
            if (res.data.Status) {
                setData(res.data.Result)
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...homeVariant} className='home-page'>
            <Header data={data} />
            <img className='d-none' onLoad={onLoadImg} src={data.KapakResmi} alt='' />
            {image1Load ? <motion.div className='anaSlayt' {...anaSlaytVariant}>
                <img src={data.KapakResmi} alt='' />
                <div dangerouslySetInnerHTML={{ __html: data.Slogan }}></div>
            </motion.div> : <div className='image1load'>
                <img src={loading} alt='' />
            </div>}

            <div className='eregli-baskan'>
                <div className='baskan'>{image2Load ? <motion.div {...baskanImgVariant}><img className='baskanimg' src={data.BaskanResim} alt='' /> </motion.div> : <div className='fotogaleri-load'><img src={loading} alt='' width={100} /></div>}</div>
                <div className='metin'>
                    <div>
                        <span>Ereğli Belediyesi Başkanı</span>
                    </div>
                    <div style={{ marginTop: 0 }}>
                        <h2 dangerouslySetInnerHTML={{ __html: data.BaskanIsim }}></h2>
                    </div>
                    <div>
                        <small>2009 yerel seçimlerinde Ereğlimizde Belediye Başkanı görevini başarıyla 2014 yılına kadar
                            sürdürmüştür.</small>
                    </div>
                </div>
            </div>

            <div className='button-location'>
                <div className='kesfet-button'><a style={{ textDecoration: 'none', color: 'white' }} href='/ilcemiz'>Ereğli'yi Keşfet</a></div>
            </div>

            <div className='ana-baslik'>
                <div className={tab === 1 ? 'active' : 'pasif'} onClick={() => setTab(1)}><span>Haberler</span></div>
                <div className={tab === 2 ? 'active' : 'pasif'} onClick={() => setTab(2)}><span>Eczaneler</span></div>
                <div className={tab === 3 ? 'active' : 'pasif'} onClick={() => setTab(3)}><span>Vefatlar</span></div>
            </div>

            <div className='header-content'>
                {
                    tab === 1 ? <motion.div {...cenazeHomeVariant} className='haberler'>
                        {
                            data.Haber !== null && data.Haber !== undefined ? data.Haber.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <a href={`/icerikdetay/${item.Id}`} style={{ textDecoration: 'none' }}>
                                            <div className='content'>
                                                {image3Load ? <motion.div {...haberImgVariant}><img src={item.KapakResmi} alt='' /></motion.div> : <div className='fotogaleri-load'><img src={loading} alt='' width={50} /></div>}
                                                <div style={{ marginLeft: 10 }}>
                                                    <div className='tarih'><span>{item.YayinTarihiYazi}</span></div>
                                                    <div className='baslik'><span>{item.Baslik}</span></div>
                                                    <div className='aciklama'><span>{item.Aciklama}</span></div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }) : null
                        }
                        <div style={{ width: '100%', textAlign: 'center' }}><div className='tumhaberler'><a href={`/genelicerik/1`}>Tüm Haberler</a></div></div>
                    </motion.div> : null
                }
                {
                    tab === 2 ? <motion.div {...cenazeHomeVariant} className='eczane'>
                        {
                            data.Eczane !== null && data.Eczane !== undefined ? data.Eczane.map((item, index) => {
                                console.log(item)
                                return (
                                    <div className='content' key={index}>
                                        <div className='ust'>
                                            <div><span>Nöbetçi</span></div>
                                            <div><span>{item.YayinTarihiYazi} {item.NobetZamani}</span></div>
                                        </div>
                                        <div className='orta'><span>{item.Baslik}</span></div>
                                        <div className='alt'>
                                            <span dangerouslySetInnerHTML={{ __html: item.Adres }}></span>
                                        </div>
                                        <div className='eczane-button'>
                                            <div className='telefon'><a href={`tel:${item.Telefon2}`}>Ara</a></div>
                                            <div className='yol-tarifi'><a href={`https://maps.google.com/maps?q=${item.Lat},${item.Lng}&language=tr&z=14&amp;output=embed`}>Yol Tarifi</a></div>
                                        </div>
                                    </div>
                                )
                            }) : null
                        }
                        <div className='tumeczaneler'><a href={`/genelicerik/4`}>Tüm Eczaneler</a></div>
                    </motion.div> : null
                }
                {
                    tab === 3 ? <motion.div {...cenazeHomeVariant} className='vefatlar'>
                        {
                            data.Vefat !== null && data.Vefat !== undefined ? data.Vefat.map((item, index) => {
                                return (
                                    <div className='content' key={index}>
                                        <div className='baslik'><span>{item.Baslik}</span></div>
                                        <div className='aciklama'><span dangerouslySetInnerHTML={{ __html: item.KisaAciklama }}></span></div>
                                    </div>
                                )
                            }) : null
                        }
                        <div className='tumvefatlar'><a href={`/genelicerik/3`}>Tüm Vefatlar</a></div>
                    </motion.div> : null
                }
            </div>

            <div className='en-alt'>
                <div className='whatsapp'>
                    <div><img src={whatsapp} alt='' /></div>
                    <div>
                        <div className='text'><a href='https://api.whatsapp.com/send/?phone=905527100707&app_absent=0' style={{ textDecoration: 'none', color: 'black' }}><span>Whatsapp İletişim Hattı</span></a></div>
                        <div className='phone'><span>0552 710 07 07</span></div>
                    </div>
                </div>
                <div className='kamera'>
                    <div><img src={kamera} alt='' /></div>
                    <div>
                        <div className='buyuk'><span>Şehir</span></div>
                        <div className='kucuk'><span>KAMERALARI</span></div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default HomePage