import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { baslik, icerikdetay, listeleicerik } from '../api';
import axios from 'axios';
import headimg from '../image/head.png'
import geri from '../image/geri.png'
import dosya from '../image/dosya.png'
import play from '../image/play.png'
import { motion } from 'framer-motion'
import { Card } from 'reactstrap'
import { cenazeHomeVariant, icerikdetayVariant } from '../component/general';

const Pages = () => {
    const { boolen, id, tip } = useParams()
    const [data, setData] = useState([])
    const [tip7, setTip7] = useState([])
    const [tip5, setTip5] = useState([])
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const counter = useRef(0);

    const imageLoaded = () => {
        if (localStorage.getItem('fotogaleri') === '1') {
            setTimeout(() => {
                setLoading(true)
            }, 1000)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setTimeout(() => {
                    setLoading(true)
                }, 1000)
            }
        }
    }

    const getData = () => {
        if (boolen === '1') {
            if (tip === '0') {
                axios.get(`${icerikdetay}${id}`).then(res => {
                    if (res.data.Status) {
                        setData(res.data.Result)
                        setTimeout(() => { localStorage.setItem('fotogaleri', '1') }, 500)
                        setLoad(true)
                    }
                })
            }
            if (tip === '7') {
                axios.get(`${listeleicerik}${id}/${tip}?p=1&l=30`).then(res => {
                    if (res.data.Status) {
                        setTip7(res.data)
                        setLoad(true)
                    }
                })
            }
            if (tip === '5') {
                axios.get(`${listeleicerik}${id}/${tip}?p=1&l=30`).then(res => {
                    if (res.data.Status) {
                        setTip5(res.data)
                        setLoad(true)
                    }
                })
            }
        }
        if (boolen === '2') {
            axios.get(`${baslik}/${id}?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data.Result)
                    setTimeout(() => { localStorage.setItem('fotogaleri', '1') }, 500)
                    setLoad(true)
                }
            })
        }
    }

    useEffect(() => {
        if (id === '268') {
            window.location.assign('/bizeYazin/1')
        } else {
            getData()
        }
    }, [])

    return (
        <motion.div {...icerikdetayVariant} className='pages'>
            <div className='head'>
                <div className='sol'>
                    <div className='head'><img src={headimg} alt='' /></div>
                    <div className='geri'><div onClick={() => window.history.back()}><img src={geri} alt='' /></div></div>
                </div>
                <div className='detay'><span>Detay</span></div>
                <div className='menuust'></div>
            </div>

            {data.length !== 0 ? <div>
                <div className='baslik'>
                    <h6>{data.Baslik}</h6>
                </div>

                {data.KapakResmi !== null && data.KapakResmi !== undefined ? <div className='kapakresim'>
                    <img src={data.KapakResmi} alt='' />
                </div> : null}

                {data.Icerik !== null && data.Icerik !== undefined ? <div className='content'>
                    <div dangerouslySetInnerHTML={{ __html: data.Icerik }}></div>
                </div> : null}

                <motion.div {...cenazeHomeVariant} className='foto-galeri'>
                    {
                        data.FotoGaleri !== null && data.FotoGaleri !== undefined ? data.FotoGaleri.map((item, index) => {
                            return (
                                <div key={index} className='item-foto'>
                                    <div className='d-none'><img src={item.Resim} alt='' onLoad={imageLoaded} /></div>
                                    <div><img src={item.Resim} alt='' onLoad={imageLoaded} /></div>
                                    {item.Baslik !== null && item.Baslik !== undefined ? <div className='name'><span className='basliks'>{item.Baslik}</span></div> : null}
                                </div>
                            )
                        }) : null
                    }
                </motion.div>

                {
                    data.DosyaGaleri !== null && data.DosyaGaleri !== undefined ? data.DosyaGaleri.map((item, index) => {
                        return (
                            <Card className='border-0 m-2 p-3' key={index}>
                                <div className='d-flex justify-content-start'>
                                    <div className='m-3'><img src={dosya} height={50} alt='' /></div>
                                    <div>
                                        <div><h6>{item.Baslik}</h6></div>
                                        <div className='mt-2'><a href={item.Dosya} style={{ color: '#dcdc0f' }}>İndir</a></div>
                                    </div>
                                </div>
                            </Card>
                        )
                    }) : null
                }

                {
                    data.VideoGaleri !== null && data.VideoGaleri !== undefined ? <div className='video-galeri'>
                        {
                            data.VideoGaleri.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <a href={item.Video} style={{ textDecoration: 'none' }}>
                                            <div>
                                                <div className='item'>
                                                    <img src={item.Resim} alt='' />
                                                    <span><img src={play} style={{ height: 30, width: 50 }} alt='' /></span>
                                                </div>
                                                <div className='content'><span>{item.Baslik}</span></div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }

            </div> : null
            }

            {
                tip7.length !== 0 ? <div className='tipler'>
                    <div className='head'>
                        <div className='sol'>
                            <div className='head'><img src={headimg} alt='' /></div>
                            <div className='geri'><a href='/'><img src={geri} alt='' /></a></div>
                        </div>
                        <div className='detay'><span>{tip7.Message}</span></div>
                        <div className='menuust'></div>
                    </div>

                    {
                        tip7.Result.map((item, index) => {
                            return (
                                <div className='tip-item' key={index}>
                                    <div className='d-flex justify-content-start'>
                                        <div className='m-2 align-items-center text-center d-flex justify-content-center'><img src={dosya} style={{ height: 50 }} alt='' /></div>
                                        <div>
                                            <div><h6>{item.Baslik}</h6></div>
                                            <div className='mt-1'><span>{item.KisaAciklama}</span></div>
                                            <div className='mt-1'><a href={`/icerikdetay/${item.Id}`}>Detay</a></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> : null
            }

            {
                tip5.length !== 0 ? <div className='tip5'>
                    {
                        tip5.Result.map((item, index) => {
                            return (
                                <div key={index} className='item'>
                                    <div className='images'><img src={item.KapakResmi} alt='' /></div>
                                    {item.Baslik !== null && item.Baslik !== undefined ? <div className='texts'><span>{item.Baslik}</span></div> : null}
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </motion.div>
    )
}

export default Pages