import React, { useEffect, useState } from 'react'
import Drawer from 'react-motion-drawer'
import home from '../image/home.png'
import belediye from '../image/hotel.png'
import menu from '../image/menu.png'
import eregli from '../image/eregli.png'
import mesaj from '../image/mesaj.png'
import '../css/style.css'
import logo from '../image/logo.png'
import face from '../image/facebook.png'
import twit from '../image/twitter.png'
import youtube from '../image/youtube.png'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'
import axios from 'axios'
import { anaSayfa } from '../api'

const Bottom = () => {
    const [canvasOpen, setCanvasOpen] = useState(false)
    const [open, setOpen] = useState('');
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(anaSayfa).then(res => {
            if (res.data.Status) {
                setData(res.data.Result)
            }
        })
    }

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => { getData() }, [])

    return (
        <div className='bottom-bar'>
            <div>
                <a href='/' style={{ textDecoration: 'none' }}>
                    <div className='item'>
                        <div><img src={home} alt='' /></div>
                        <div className='text'><span>Anasayfa</span></div>
                    </div>
                </a>
            </div>
            <div>
                <div className='item' onClick={() => setCanvasOpen(!canvasOpen)}>
                    <div><img src={menu} alt='' /></div>
                    <div className='text'><span>Menü</span></div>
                </div>
            </div>
            <div>
                <a href='/belediyemiz' style={{ textDecoration: 'none' }}>
                    <div className='item'>
                        <div><img src={belediye} alt='' /></div>
                        <div className='text'><span>Belediyemiz</span></div>
                    </div>
                </a>
            </div>
            <div>
                <a href='/ilcemiz' style={{ textDecoration: 'none' }}>
                    <div className='item'>
                        <div><img src={eregli} alt='' /></div>
                        <div className='text'><span>Ereğli</span></div>
                    </div>
                </a>
            </div>
            <div>
                <a href='/bizeYazin/0' style={{ textDecoration: 'none' }}>
                    <div className='item'>
                        <div><img src={mesaj} alt='' /></div>
                        <div className='text'><span>Bize Yazın</span></div>
                    </div>
                </a>
            </div>

            <Drawer open={canvasOpen} toggle={() => { setCanvasOpen(!canvasOpen) }} className='drawermenu'>
                <div className='w-100 d-flex justify-content-end' onClick={() => setCanvasOpen(!canvasOpen)}><div className='close'>x</div></div>

                <div className='logo'><img src={logo} alt='' /></div>

                <div className='menuler'>
                    <Accordion flush open={open} toggle={toggle}>
                        {
                            data.SolMenu !== null && data.SolMenu !== undefined ? data.SolMenu.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            item.AltMenu !== undefined ? <AccordionItem>
                                                <AccordionHeader targetId={index.toString()}>{item.Baslik}</AccordionHeader>
                                                {
                                                    item.AltMenu !== undefined ? <AccordionBody accordionId={index.toString()}>
                                                        {
                                                            item.AltMenu.map((value, key) => {
                                                                return (
                                                                    <div key={key} className='altbaslik'>
                                                                        {value.Tip !== 7 && value.Tip !== 5 ? <a href={`/icerik/${`1`}/${value.Id}/0`} style={{ textDecoration: 'none', color: 'black' }}>
                                                                            <div>{value.Baslik}</div>
                                                                        </a> : <a href={`/icerik/${`1`}/${value.Id}/${value.Tip}`} style={{ textDecoration: 'none', color: 'black' }}><div>{value.Baslik}</div></a>}
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </AccordionBody> : null
                                                }
                                            </AccordionItem> : <div className='altsiz'>
                                                {item.Baslik !== 'Uygulama Hakkında' ? <a href={item.Id !== 484 ? item.Tip !== '2' || item.Tip !== '3' || item.Tip !== '4' || item.Tip !== '5' || item.Tip !== '6' || item.Tip !== '8' ? `/genelicerik/${item.Tip}` : `/icerik/${`2`}/${item.Tip}` : null} style={{ textDecoration: 'none', color: 'black' }}>{item.Baslik}</a> : null}
                                            </div>
                                        }
                                    </div>
                                )
                            }) : null
                        }
                    </Accordion>
                </div>

                <hr style={{ color: 'grey', width: '260px', marginLeft: 20 }} />

                <div className='social'>
                    <div><img src={face} alt='' /></div>
                    <div><img src={twit} alt='' /></div>
                    <div><img src={youtube} alt='' /></div>
                </div>
            </Drawer>
        </div>
    )
}

export default Bottom