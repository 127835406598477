import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { baslik } from '../api'
import headimg from '../image/head.png'
import geri from '../image/geri.png'
import { useParams } from 'react-router-dom'
import '../css/style.css'
import { motion } from 'framer-motion'
import { Button } from 'reactstrap'
import { cenazeHomeVariant, icerikVariant, imageVariant, newsVariant } from '../component/general'
import loading from '../image/loading.svg'

const GenelIcerik = () => {
    const [data, setData] = useState([])
    const { tip } = useParams()
    const [load, setLoad] = useState(false)
    const [fotoGaleriLoad, setFotoGaleriLoad] = useState(false)
    const [newsLoaded, setFotoNewsLoad] = useState(false)
    const counter = useRef(0);
    const imageLoaded = () => {
        if (localStorage.getItem('fotogalerisave') === '1') {
            setTimeout(() => {
                setFotoGaleriLoad(true)
            }, 700)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setFotoGaleriLoad(true);
            }
        }
    }

    const imageNewsLoaded = () => {
        if (localStorage.getItem('imgNews') === '1') {
            setTimeout(() => {
                setFotoNewsLoad(true)
            }, 700)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setFotoNewsLoad(true);
            }
        }
    }


    const getData = () => {
        if (tip === undefined || tip === null) {
            console.log('girdi')
            window.location.replace('/')
        }
        if (tip === '1') {
            axios.get(`${baslik}/Haber?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                    localStorage.setItem('imgNews', '1')
                    if (localStorage.getItem('imgNews') === '1') {
                        setTimeout(() => {
                            setFotoNewsLoad(true)
                        }, 1200)
                    } else {
                        setTimeout(() => {
                            setFotoNewsLoad(true)
                        }, 700)
                    }
                }
            })
        } else if (tip === '2') {
            axios.get(`${baslik}/Duyuru?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                }
            })
        } else if (tip === '3') {
            axios.get(`${baslik}/Vefat?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                }
            })
        } else if (tip === '4') {
            axios.get(`${baslik}/Eczane?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                }
            })
        } else if (tip === '5') {
            axios.get(`${baslik}/Foto?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                    localStorage.setItem('fotogalerisave', '1')
                    if (localStorage.getItem('fotogalerisave') === '1') {
                        setTimeout(() => {
                            setFotoGaleriLoad(true)
                        }, 1200000)
                    } else {
                        setTimeout(() => {
                            setFotoGaleriLoad(true)
                        }, 700)
                    }
                }
            })
        } else if (tip === '6') {
            axios.get(`${baslik}/Video?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                }
            })
        } else if (tip === '8') {
            axios.get(`${baslik}/Ihale?p=1&l=30`).then(res => {
                if (res.data.Status) {
                    setData(res.data)
                    setLoad(true)
                }
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='genel-icerik'>
            <div className='head'>
                <div className='sol'>
                    <div className='head'><img src={headimg} alt='' /></div>
                    <div className='geri'><div onClick={() => window.history.back()}><img src={geri} alt='' /></div></div>
                </div>
                <div className='detay'><span>{tip === '1' ? 'Haberler' : ''}{tip === '2' ? 'Duyurular' : ''}{tip === '3' ? 'Vefat Haberleri' : ''}{tip === '4' ? 'Eczaneler' : ''}{tip === '5' ? 'Foto Galeri' : ''}{tip === '6' ? 'Video Galeri' : ''}{tip === '8' ? 'İhaleler' : ''}</span></div>
                <div className='menuust'></div>
            </div>
            {/*
            <div className='baslik'>
                <h6>{data.Message}</h6>
            </div> */}


            {
                data.ListType === 5 ? <motion.div {...cenazeHomeVariant} className='foto-galeri'>
                    {
                        data.Result.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img className='d-none' src={item.KapakResmi} onLoad={imageLoaded} alt='' />
                                    {fotoGaleriLoad ? <div className='item'>
                                        <a href={`/icerikdetay/${item.Id}`} style={{ color: 'black', textDecoration: 'none' }}>
                                            <motion.div {...imageVariant}><div><img src={item.KapakResmi} onLoad={imageLoaded} alt='' />
                                                <div style={{ height: 33 }}><span>{item.Baslik}</span></div></div></motion.div>
                                        </a>
                                    </div> : <div className='fotogaleri-load' key={index}>
                                        <div><img src={loading} alt='' /></div>
                                    </div>}
                                </div>
                            )
                        })
                    }
                </motion.div> : null
            }

            {
                data.ListType !== 5 ? data.Result !== null && data.Result !== undefined ? data.Result.map((item, index) => {
                    return (
                        <motion.div {...icerikVariant} key={index}>
                            <img src={item.KapakResmi} width={120} alt='' onLoad={imageNewsLoaded} className='d-none' />
                            <div className='duyuru'>
                                <a href={tip !== '4' ? `/icerikdetay/${item.Id}` : null} style={{ textDecoration: 'none' }}>
                                    <div className='d-flex justify-content-start'>
                                        {item.KapakResmi !== null && item.KapakResmi !== undefined ? newsLoaded ? <div className='align-items-center text-center m-2'>
                                            <motion.div {...newsVariant}><img src={item.KapakResmi} width={120} alt='' onLoad={imageNewsLoaded} /></motion.div>
                                        </div> : <div className='fotogaleri-load' key={index}>
                                            <div><img src={loading} alt='' /></div>
                                        </div> : null}
                                        <div>
                                            <div className='date text-black'><span>{item.YayinTarihiYazi}</span></div>
                                            <div className='mt-2 p-2 text-black'><h6>{item.Baslik}</h6></div>
                                            {item.KisaAciklama !== null && item.KisaAciklama !== undefined ? <div className='text-black aciklama'>
                                                <span dangerouslySetInnerHTML={{ __html: item.KisaAciklama }}></span>
                                            </div> : item.Telefon2 !== undefined ? <div className='text-black aciklamas'>
                                                <span dangerouslySetInnerHTML={{ __html: item.Adres }}></span>
                                                <div className='butonlar'>
                                                    <div>
                                                        <a href={`tel:${item.Telefon2}`}><Button className='mt-3'>Ara</Button></a>
                                                    </div>
                                                    <div className='butonyeri'>
                                                        <a href={`https://maps.google.com/maps?q=${item.Harita}&language=tr&z=14&amp;output=embed`}>
                                                            <Button className='mt-2'>Yol Tarifi</Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </motion.div>
                    )
                }) : null : null
            }
        </div >
    )
}

export default GenelIcerik