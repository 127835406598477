import { BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from './component/AnimatedRoutes'
import Bottom from './component/bottom'

const App = () => {

  return (
    <>
      <Router>
        <AnimatedRoutes  />
        <Bottom />
      </Router>
    </>
  )
}

export default App